import React, { useContext, useEffect } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Section, Wysiwyg, Image } from "../atoms";

import { sizes, colors, getSiteMetaData, pushTeliumView } from "../utils";
import { localeShape, errorPageFieldsShape } from "../utils/shapes";
import { heroTitle, CTAs } from "../utils/textStyles";
import { GlobalContext } from "../context/globalContext";

const StyledErrorPage = styled(Section)`
  position: relative;
  min-height: 60vh;
  padding: 4rem 1rem 0;

  @media (min-width: ${sizes.desktop}) {
    display: flex;
    flex-direction: row;
    align-items: bottom;
    justify-content: space-between;
    padding: 8rem 4% 0 14%;
    min-height: 10rem;
  }

  @media (min-width: ${sizes.xldesktop}) {
    padding: 12rem 10% 0 25%;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
    background: ${colors.gray100};
    z-index: -1;
  }

  .content {
    width: 50%;
    margin-bottom: 2rem;
    padding-top: 5.5rem;

    @media (min-width: ${sizes.xldesktop}) {
      width: 40%;
      padding-top: 10rem;
    }

    @media (min-width: ${sizes.huge}) {
      padding-top: 12rem;
    }
  }

  h1 {
    ${heroTitle}
    margin-bottom: 0.8rem;
  }

  .copy {
    margin-bottom: 1.5rem;
  }

  .home-link {
    ${CTAs}
    text-transform: uppercase;
    color: ${({ theme }) =>
      theme.brand === "TH" ? colors.green : colors.black};
  }

  .home-link:hover {
    text-decoration: none;
  }

  .media {
    display: none;

    @media (min-width: ${sizes.desktop}) {
      display: block;
      position: relative;
      width: 50%;
      height: 100%;
    }
  }

  .media img {
    object-fit: cover;
  }
`;

const ErrorPage = ({ pageContext: { locale, page } }) => {
  const { title, heading, copy, homeButtonLabel, tommyMedia, ckMedia } = page;
  const langCode = locale.code.substring(0, 2);

  const { state } = useContext(GlobalContext);

  const { theme } = getSiteMetaData();
  const media = theme === "TH" ? tommyMedia : ckMedia;

  const pageData = {
    page_title: title,
    page_type: "home",
    page_locale: locale.code
  };

  useEffect(() => {
    pushTeliumView({
      tealium_event: "page_view",
      ...pageData
    });

    state.updateState({ page: { ...pageData } });
  }, []);

  return (
    <StyledErrorPage>
      <div className="bg" />
      <div className="content">
        <h1>{heading}</h1>
        <div className="copy">
          <Wysiwyg content={copy} />
        </div>
        <Link className="home-link" to={`/${langCode}`}>
          {homeButtonLabel}
        </Link>
      </div>
      <div className="media">
        <Image image={media} />
      </div>
    </StyledErrorPage>
  );
};

ErrorPage.propTypes = {
  pageContext: PropTypes.shape({
    page: errorPageFieldsShape,
    locale: localeShape.isRequired
  }).isRequired
};

export default ErrorPage;
